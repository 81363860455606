import {FILE_UPLOAD_OPERATION_EDIT_METADATA_DIALOG, useAppDispatch, useAppState} from "../../app/AppContext";
import React, {useCallback, useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {Dialog, DialogContent} from "@mui/material";
import {damsSearch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {clientLog} from "../../clientLog";
import {BatchEditForm} from "../../documents/BatchEditForm";
import {FormMetadata} from "../../metadata/FormMetadata";
import {BatchEditActions} from "../../documents/BatchEditActions";
import {useDocumentTranslation} from "../../documents/documentContext";


/**
 * A dialog component that displays a form for editing the metadata of the selected documents
 * in the current file upload operation.
 *
 * The dialog is opened when the `fileUploadOperationEditMetadataDialog` action is dispatched
 * with an `open` property set to `true`.
 *
 * The dialog will load the selected documents from the API and display a `BatchEditForm`
 * component with a `FormMetadata` component inside. The `FormMetadata` component is a form
 * that allows the user to edit the metadata of the documents.
 *
 * When the user clicks the "Lagre" button, the `BatchEditForm` component will send a request
 * to the API to save the changes. If the request is successful, the dialog will close and
 * the `callback` function will be called.
 *
 * If the user clicks the "Avbryt" button, the dialog will close and the `callback` function will
 * not be called.
 *
 * @param {function} callback - A callback function that will be called when the user clicks the "Lagre" button.
 * @returns {JSX.Element} A dialog component with a form for editing the metadata of the selected documents.
 */
export const DialogMetadataForm = ({callback}) => {

    const t = useDocumentTranslation();
    const {fileUploadOperationEditFiles, operations} = useAppState();

    const [loading, setLoading] = useState(true)
    const [openDialog, setOpenDialog] = useState(false);
    const [documents, setDocuments] = useState([]);

    const appDispatch = useAppDispatch();

    /**
     * Get the collection ID from the current file upload operation.
     *
     * @returns {number} The collection ID.
     */
    const getCollectionId = useCallback(() => {
        const operation = operations.find(o => o.jobId === fileUploadOperationEditFiles.operationId);
        if (!operation) {
            return undefined;
        }
        return operation.collectionId;
    }, [fileUploadOperationEditFiles.operationId, operations]);

    /**
     * Load documents from selected document IDs in the current file upload operation.
     * @returns {Promise<Array<import('../../documents/documentContext').Document>>}
     */
    const loadDocuments = useCallback(async () => {
        const query = `unique_id: (${fileUploadOperationEditFiles.documentIds.join(' or ')})`;
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: query,
                fq: "status: (draft)",
                fl: "title,id",
                sort: "title asc",
                documentType: '(StillImage OR Audio OR Video OR Misc OR Dokument OR Geodata OR Tabell OR Modell)',
                expand: true,
                collection_id: getCollectionId()
            })
        );

        try {
            const res = await damsSearch(searchParams);
            if (res.count > 0) {
                return res.models;
            } else {
                return [];
            }
        } catch (e) {
            clientLog('error', 'failed to load selected documents', 'fileupload');
            return [];
        }
    }, [fileUploadOperationEditFiles, getCollectionId]);

    /**
     * Close the metadata edit dialog.
     *
     * When called, this function dispatches an action to the app context to close the
     * metadata edit dialog.
     */
    const closeDialog = () => {
        const {operationId, documentIds} = fileUploadOperationEditFiles;
        appDispatch({
            type: FILE_UPLOAD_OPERATION_EDIT_METADATA_DIALOG,
            operationId: operationId,
            documentIds: documentIds,
            open: false,
        });
    };

    /**
     * A callback function that is called when the user clicks the "Lagre" button in the
     * metadata edit dialog.
     *
     * The function takes two parameters, `saved` and `failed`. `saved` is an array of
     * documents that were successfully saved, and `failed` is an array of documents that
     * failed to save.
     *
     * If `failed` is not empty, it will be logged to the console. Otherwise, the dialog
     * will close and the `callback` function passed to `DialogMetadataForm` will be called.
     *
     * @param {Array<import('../../documents/documentContext').Document>} saved - An array of documents that were successfully saved.
     * @param {Array<import('../../documents/documentContext').Document>} failed - An array of documents that failed to save.
     */
    const onComplete = (saved, failed) => {
        if (failed.length > 0) {
            console.warn('failed files: ', failed);
        } else {
            closeDialog();
            callback([...fileUploadOperationEditFiles.documentIds]);
        }
    };

    /**
     * A callback function that is called when the user clicks the close button in the
     * metadata edit dialog.
     *
     * The function will close the dialog and call the `callback` function passed to
     * `DialogMetadataForm`.
     */
    const onHide = () => {
        closeDialog();
        callback(undefined);
    };

    /**
     * Hook used to load the data for the selected documents, and then open the dialog.
     */
    useEffect(() => {
        const {documentIds, open} = fileUploadOperationEditFiles;
        if (!open) {
            // Close dialog immediately.
            setDocuments([]);
        }

        if (!documentIds) {
            return;
        }

        // Load documents, and open the dialog.
        loadDocuments().then((data) => {
            setDocuments([...data]);
            setLoading(false);
            setOpenDialog(open);
        });
    }, [fileUploadOperationEditFiles, loadDocuments]);

    return !loading
        && documents.length > 0
        && <Dialog open={openDialog}
                   PaperProps={{
                       sx: {
                           minWidth: {
                               xs: '90vw',
                               sm: '90vw',
                               md: '90vw',
                               lg: '60vw',
                               xl: '60vw',
                           }
                       }
                   }}>
            <DialogTitle>
                {t('dialogEditMetadataTitle', 'Rediger Metadata')}</DialogTitle>
            <DialogContent>
                <BatchEditForm onComplete={onComplete}>
                    <FormMetadata batchEdit={true} collectionId={getCollectionId()}/>
                    <BatchEditActions
                        onCancel={onHide}
                        numberOfDocuments={fileUploadOperationEditFiles.documentIds.length}
                    />
                </BatchEditForm>
            </DialogContent>
        </Dialog>
};