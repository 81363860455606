import React, {Suspense, useEffect, useState} from "react";
import {SearchResultDetailsProvider} from "../search-result-details/SearchResultDetailsContext";
import {DocumentTypeProvider} from "../document-type/documentTypeContext";
import {
    SET_QUERY,
    SET_ROWS,
    SET_SEARCHING,
    SET_START,
    SHOW_INSTANTLY,
    useSearchDispatch,
    useSearchState,
    useSearchTranslation,
} from "./SearchContext";
import {MuseumSelector} from "../museum/MuseumSelector";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {SearchSettings} from "./SearchSettings";
import {SearchInput} from "./SearchInput";
import {ButtonInvokeUpload} from "../damsfileupload3/ButtonInvokeUpload";
import Box from "@mui/material/Box";
import {Stack, useMediaQuery} from "@mui/material";
import SearchResults from "./SearchResults";

/**
 * A page component used to display the search form and results.
 *
 * @param {object} props The component's props.
 * @param {boolean} props.startSearched Whether to start searching immediately.
 * @returns {JSX.Element} The component.
 */
const SearchLandingPage = ({startSearched = false}) => {

    const t = useSearchTranslation();

    const [searched, setSearched] = useState(startSearched);
    const [inputValue, setInputValue] = useState("");

    const {onlyMine, query, results} = useSearchState();

    const searchDispatch = useSearchDispatch();

    const {searchString} = useParams();

    const searchSettings = SearchSettings('dams.searchResults.config');

    const smallScreen = useMediaQuery("(max-width: 768px)");

    /**
     * Gets the ID of a shared object from the current URL.
     *
     * The ID is extracted from the "view" parameter of the URL.
     *
     * @returns {string|null} The ID of the shared object if present, otherwise null.
     */
    const getSharedObjectId = () => {
        const params = new URLSearchParams(window.location.search);
        return params.get("view");
    };

    /**
     * Sets the search criteria to the given value and starts a new search.
     *
     * @param {string} inValue The new search value.
     * @param {string} q The query to use for the search.
     *
     * This function is used to set the search criteria to the given value and
     * start a new search. If the value is empty, it will clear the search criteria.
     *
     * This function is used by the search form and by the URL handling code when
     * the user navigates to a URL with a "view" parameter.
     */
    const setCriteria = (inValue, q) => {
        setInputValue(inValue);
        setSearched(true);
        searchDispatch({
            type: SET_QUERY,
            query: q,
            folderContext: false,
        });
    };

    /**
     * Sets the criteria used to fetch shared objects.
     *
     * NOTE:
     * This method is called when the application is accessed by a user who has received an e-mail with shared objects
     * from a "regular" DAMS-user.
     * @param sharedObjectId
     */
    const setSharedObjectsCriteria = (sharedObjectId) => {
        const q = '"' + sharedObjectId + '"';
        searchDispatch({
            type: SET_START,
            start: 0,
        });
        console.log('setting query: ', q);
        searchDispatch({
            type: SET_QUERY,
            query: q,
            folderContext: false,
            timestamp: new Date().getTime()
        });
        searchDispatch({type: SET_SEARCHING});
        searchDispatch({
            type: SHOW_INSTANTLY,
            showInstantly: sharedObjectId
        });
        setSearched(true);
    };

    /**
     * Sets the criteria used when executing a regular search.
     */
    const setRegularSearchCriteria = () => {
        if (!searchString || searchString === "*") {
            setCriteria("", "*");
        } else if (searchString) {
            setCriteria(searchString, searchString);
        }
        const rows = searchSettings.getNumberOfRows() || 10;
        searchDispatch({
            type: SET_ROWS,
            rows: rows
        });
    };

    /**
     * Executes search when the user presses the "Enter" key.
     * @param event
     * @param value
     */
    const searchOnEnter = (event, value) => {
        if ("Enter" === event.key) {
            let q = inputValue || value;
            if (q === "") {
                q = "*";
            }
            searchDispatch({
                type: SET_START,
                start: 0,
            });
            searchDispatch({
                type: SET_QUERY,
                query: q,
                folderContext: false,
                timestamp: new Date().getTime()
            });
            searchDispatch({
                type: SET_SEARCHING
            });
            setSearched(true);
        }
    };

    /**
     * Displays the museum selector / filter, when outside "my files".
     * @returns {JSX.Element}
     */
    const getMuseumSelector = () => {
        if (!onlyMine) {
            return <MuseumSelector/>;
        }
    };

    /**
     * Hook used to execute the appropriate search.
     */
    useEffect(() => {
        setRegularSearchCriteria();
        if (!searched) {
            return;
        }
        const sharedObjectId = getSharedObjectId();
        if (sharedObjectId) {
            setSharedObjectsCriteria(sharedObjectId);
        } else {
            setRegularSearchCriteria();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, searchDispatch, searched]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            flexGrow: 1,
            alignItems: 'stretch',
            justifyContent: 'stretch',
        }}>
            <DocumentTypeProvider>
                {smallScreen &&
                    <Suspense fallback={<></>}>
                        <Box sx={{padding: '16px'}}>
                            <Typography variant={"h5"}
                                        component={"h2"}
                                        id={"search-results-title"}
                                        sx={{marginBottom: '8px'}}>
                                {onlyMine
                                    ? t("searchResultsMyFiles", "Mine filer")
                                    : t("searchResults", "Søkeresultat")}
                            </Typography>
                            <SearchInput searchOnEnterCallback={searchOnEnter}
                                         museumMenu={getMuseumSelector()} small={smallScreen}/>
                        </Box>
                    </Suspense>
                }
                {!smallScreen &&
                    <Suspense fallback={<></>}>
                        <Stack direction={"row"}
                               sx={{
                                   marginLeft: '32px',
                                   marginRight: '16px',
                                   marginTop: '32px',
                                   marginBottom: '32px'
                               }}
                               justifyContent={"space-between"}
                               alignItems={"center"}>
                            <Box sx={{display: 'flex', flexGrow: 1, marginLeft: '8px'}}>
                                <Typography variant={"h5"} component={"h2"} id={"search-results-title"}>
                                    {onlyMine
                                        ? t("searchResultsMyFiles", "Mine filer")
                                        : t("searchResults", "Søkeresultat")}
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', flexGrow: 1}}>
                                <SearchInput searchOnEnterCallback={searchOnEnter}
                                             museumMenu={getMuseumSelector()} small={smallScreen}/>
                            </Box>
                            <Box sx={{display: 'flex', flexGrow: 1}}></Box>
                            <Box sx={{marginRight: '24px'}}>
                                <ButtonInvokeUpload/>
                            </Box>
                        </Stack>
                    </Suspense>
                }
                <SearchResultDetailsProvider>
                    {searched && <SearchResults/>}
                </SearchResultDetailsProvider>
                {smallScreen && searched &&
                    <Box sx={(theme) => ({
                        backgroundColor: theme.palette.primary.main,
                        width: '100%',
                        display: searched ? 'block' : 'none',
                        textAlign: 'center'
                    })}>
                        <ButtonInvokeUpload overrideSmallScreen={true}/>
                    </Box>}
            </DocumentTypeProvider>
        </Box>
    );
};

export default React.memo(SearchLandingPage);