import {Box, Button, Toolbar} from "@mui/material";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useSearchState} from "../SearchContext";

/**
 * Delete files modal action buttons component.
 * Used by the DeleteFilesModal and RemoveFilesModal components.
 *
 * @param {function} t - Translation function
 * @param {function} cancelCallback - Callback function for cancel action
 * @param {function} deleteCallback - Callback function for delete action
 * @return {JSX.Element} The rendered JSX for the component
 */
const DeleteFilesModalActionButtons = ({t, cancelCallback, deleteCallback}) => {
    const {deleting} = useSearchState();

    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        setIsDeleting(deleting);
    }, [deleting]);

    return <Toolbar>
        <Box flexGrow={1}/>
        <Button onClick={cancelCallback}>{t("cancel", "Avbryt")}</Button>
        <Button
            variant={"contained"}
            color={"secondary"}
            sx={{
                marginLeft: "16px",
            }}
            disabled={deleting}
            onClick={deleteCallback}
            loading={isDeleting}
            loadingPosition="end"
        >
            {t("removeDocumentsButton", "Ok")}
        </Button>
    </Toolbar>;
};

DeleteFilesModalActionButtons.propTypes = {
    "t": PropTypes.func.isRequired,
    "cancelCallback": PropTypes.func.isRequired,
    "deleteCallback": PropTypes.func.isRequired
};

export {DeleteFilesModalActionButtons};