import React, {useEffect, useState} from "react";
import {DamsForm} from "../form/DamsForm";
import {usePostDocuments} from "./usePostDocuments";
import {useBatchEditState} from "./batchEditContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {useDocumentState, useDocumentTranslation} from "./documentContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import Box from "@mui/material/Box";
import {getMuseumsFromCollectionIds} from "../utility";
import {useAuthsState} from "../auths/authsContext";
import {CreateMissingDocRefs} from "./createMissingDocRefs";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsSearch} from "../app/damsFetch";
import {clientLog} from "../clientLog";
import {useAppState} from "../app/AppContext";

/**
 * Renders a form for batch editing of documents.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.models - The original models to be edited.
 * @param {Function} props.onComplete - Callback function to be called when all documents are finished.
 * @param {ReactNode} props.children - The child components to be rendered inside the form.
 * @return {JSX.Element} The rendered form component.
 */
export const BatchEditForm = ({models, onComplete, children}) => {
    const {fileUploadOperationEditFiles} = useAppState();
    const {userData, museumCollections} = useAuthsState();
    const [loading, setLoading] = useState(true);
    const [museums, setMuseums] = useState([]);

    const t = useDocumentTranslation();
    // eslint-disable-next-line no-unused-vars
    const [postDocumentsResponse, postDocuments, postDocumentsInChunks] = usePostDocuments(); // NOSONAR
    const {selectedFields} = useBatchEditState();
    const {failed, saved} = useDocumentState();
    const snackbarDispatch = useSnackbarDispatch();

    // When operating in batch-mode, the initial values of the form fields are empty.
    // Values are appended to the existing field values, or overwrites the existing field values when a document is saved
    const initialValues = {
        title: '',
        description: '',
        remarks: '',
        productionDate: null,
        producer: [],
        persons: [],
        places: [],
        subjects: [],
        licenses: [],
        languages: [],
        relations: [],
        copyrightInfo: [],
        copyrightType: [],
        copyrightTypeDateUntil: [],
        copyrightTypeOriginator: [],
        copyrightTypeResponsible: [],
        copyrightTerms: ''
    };

    // NOTE: only append the referenceTypes field if it is selected.
    if (selectedFields.find(f => f.name === 'referenceTypes')) {
        initialValues.referenceTypes = [];
    }


    /**
     * Shows a snackbar message with the given message and an error type.
     * @param {string} message - The message to be displayed in the snackbar.
     */
    const showErrorSnackbar = (message) => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("snackbarBatchEditError", "Lagring feilet"),
                body: message,
                type: "error",
            },
        });
    };

    /**
     * Appends reference types to the updated model, if selected for update.
     *
     * The following fields are updated, if the corresponding newValues field is non-empty:
     * - referenceTypes
     * @param {Object} newValues - The new values to be merged into the models.
     * @param {Array<Object>} updatedModels - The updated models.
     * @param {number} n - The index of the model to be updated.
     */
    const appendReferenceTypes = (newValues, updatedModels, n) => {
        if (newValues.referenceTypes && newValues.referenceTypes.length > 0) {
            updatedModels[n].content.referenceTypes = newValues.referenceTypes;
        }
    };

    /**
     * Appends copyright and licensing information to the updated models, if selected for update.
     *
     * @param {Object} newValues - The new values to be appended to the existing models.
     * @param {Array<Object>} updatedModels - The updated models to be modified.
     * @param {number} n - The index of the current model in the updatedModels array.
     */
    const appendCopyrightAndLicensingInformation = (newValues, updatedModels, n) => {
        /**
         * Appends copyright information to the updated model.
         *
         * If the newValues.copyrightInfo array is not empty, the content of the
         * array is appended to the updated model's copyrightInfo array.
         * Additionally, the responsible persons specified in the newValues object
         * are added to the corresponding index in the updated model's copyrightInfo
         * array.
         */
        function appendCopyrightInfo() {
            if (newValues.copyrightInfo && newValues.copyrightInfo.length > 0) {
                updatedModels[n].content.copyrightInfo = newValues.copyrightInfo;

                // Append copyright info responsible,
                // as this is stored in a parallel structure in the newValues object:
                const {collectionId} = updatedModels[n];
                const i = newValues.copyrightInfo.length;
                for (let j = 0; j < i; j++) {
                    const responsible = newValues[`copyrightResponsible${j}`]
                        && newValues[`copyrightResponsible${j}`].filter(r => r.collectionId === collectionId);
                    if (responsible) {
                        updatedModels[n].content.copyrightInfo[j].responsible = responsible;
                    }
                }
            }
        }

        /**
         * Appends copyright type information to the updated models, if selected for update.
         *
         * The following fields are updated, if the corresponding newValues field is non-empty:
         * - copyrightType
         * - copyrightTypeDateUntil
         * - copyrightTypeOriginator
         * - copyrightTypeResponsible
         */
        function appendCopyrightType() {
            const {collectionId} = updatedModels[n];
            if (newValues.copyrightType && newValues.copyrightType !== '') {
                updatedModels[n].content.copyrightType = newValues.copyrightType;
            }
            if (newValues.copyrightTypeDateUntil && newValues.copyrightTypeDateUntil !== '') {
                updatedModels[n].content.copyrightTypeDateUntil = newValues.copyrightTypeDateUntil;
            }
            if (newValues.copyrightTypeOriginator && newValues.copyrightTypeOriginator.length > 0) {
                updatedModels[n].content.copyrightTypeOriginator =
                    newValues.copyrightTypeOriginator.filter(r => r.collectionId === collectionId);
            }
            if (newValues.copyrightTypeResponsible && newValues.copyrightTypeResponsible.length > 0) {
                updatedModels[n].content.copyrightTypeResponsible =
                    newValues.copyrightTypeResponsible.filter(r => r.collectionId === collectionId);
            }
        }

        if (selectedFields.find(f => f.name === 'copyrightAndLicensing')) {
            // Append license information, if it is selected for update.
            // NOTE: These fields are not eligible for overwrite or merge.
            const {collectionId} = updatedModels[n];
            const {licenses} = {...newValues};
            if (licenses && licenses.length > 0) {
                let licensesOut = [];
                for (let j = 0, max = licenses.length; j < max; j++) {
                    let license = [...licenses][j];
                    const responsible = license.responsible.filter(r => r.collectionId === collectionId);
                    licensesOut.push({...license, responsible});
                }
                updatedModels[n].content.licenses = licensesOut;
            }
            appendCopyrightType();
            appendCopyrightInfo();
        }
    };

    /**
     * Utility function that merges the new field value into the existing field values.
     *
     * The merging rules are as follows:
     * - If the existing field value is null or undefined, set it to the new value.
     * - If the new value is not an array, append the new value to the existing field value.
     * - If the new value is an array and contains values with the same collectionId as the existing field value,
     *   append the new values to the existing field value, without duplicates, based on the 'title' property.
     *
     * @param {Number} collectionId - The collectionId of the existing field value.
     * @param {Any} modelField - The existing field value.
     * @param {Any} newValue - The new field value to be merged.
     * @return {Any} The resulting merged field value.
     */
    const mergeFieldValues = (collectionId, modelField, newValue) => {
        if (modelField === null || typeof (modelField) === 'undefined') {
            modelField = newValue;
        } else {
            if (!Array.isArray(newValue)) {
                modelField = `${modelField} ${newValue}`;
            } else if (Array.isArray(newValue)) {
                const data = [...modelField, ...newValue];
                if (data.some(d => d.reference?.id)) {
                    modelField = Array.from(
                        data.reduce((map, item) => {
                            const id = item.reference.id;
                            if (!map.has(id)) {
                                map.set(id, item);
                            }
                            return map;
                        }, new Map()).values()
                    );
                } else {
                    modelField = data;
                }
            }
        }
        return modelField;
    };

    /**
     * Utility function that overwrites or merges the new field value into the existing field values,
     * based on the given function name 'fn'.
     *
     * If 'fn' is 'merge', the new value is merged into the existing field values, without duplicates,
     * based on the 'title' property.
     *
     * If 'fn' is 'overwrite', the new value overwrites the existing field values, without duplicates,
     * based on the 'title' property.
     *
     * @param {string} fn - The function name, either 'merge' or 'overwrite'.
     * @param {Array<Number>} allCollectionIds - All collectionIds of the existing field values.
     * @param {Any} modelField - The existing field value.
     * @param {Number} collectionId - The collectionId of the existing field value.
     * @param {Any} newValue - The new field value to be merged or overwritten.
     * @return {Any} The resulting merged or overwritten field value.
     */
    const overwriteOrMergeValue = (fn, allCollectionIds, modelField, collectionId, newValue) => {
        if (fn === 'merge') {
            const mergedValues = mergeFieldValues(collectionId, modelField, newValue);
            modelField = getUniqueMergedValues(collectionId, mergedValues);
        } else if (fn === 'overwrite') {
            if (Array.isArray(newValue) && newValue['collectionId']) {
                modelField = getUniqueOverwriteValues(collectionId, newValue);
            } else {
                // "Simple values": strings, numbers, booleans and languages, which is does not require a
                // reference to be created in the database.
                modelField = newValue;
            }
        }
        return modelField;
    };

    /**
     * @function getUniqueOverwriteValues
     * @description
     * Overwrite field values with the given values, but only if the values are not already present in the field with the same collectionId.
     * @param {Number} collectionId - The collectionId of the field values to be overwritten.
     * @param {Array} newValue - The new values to overwrite the existing field values with.
     * @return {Array} The overwritten field values.
     */
    const getUniqueOverwriteValues = (collectionId, newValue) => {
        // Removing _action:put from hasCorrectCollectionId array items: ._action and .reference._action,
        // to avoid creating references automatically, as these have already been created.
        const hasCorrectCollectionId = newValue.filter(nv => nv.collectionId === collectionId).map(v => {
            const value = {
                ...v,
                reference: {
                    ...v.reference
                }
            };
            delete value._action;
            delete value.reference._action;
            return value;
        });

        // Find all items that does not have the same collection ID as the current collectionId,
        // and remove the _action:put from them: ._action and .reference._action, along with collectionId,
        // id, uniqueId.
        const isMissingCorrectCollectionId =
            newValue.filter(nv => nv.collectionId !== collectionId)
                .filter(nvv => !hasCorrectCollectionId.map(t => t.reference.title).includes(nvv.reference.title))
                .map(vv => {
                    const refValue = {
                        ...vv,
                        reference: {
                            ...vv.reference
                        }
                    };
                    delete refValue._action;
                    delete refValue.collectionId;
                    delete refValue.reference.id;
                    delete refValue.reference.uniqueId;
                    delete refValue.reference._action;
                    return refValue;
                });
        return [...hasCorrectCollectionId, ...isMissingCorrectCollectionId];
    };


    /**
     * Merges the given field values for the given collectionId and returns
     * the unique values.
     *
     * This function takes the given array of values and splits it into two
     * arrays: values that have the same collectionId as the given collectionId
     * and values that don't. It then filters out any values that don't have the
     * same collectionId from the second array and returns the merged array.
     *
     * The purpose of this function is to merge values from different collections
     * into a single array of values while avoiding any duplicates.
     *
     * @param {Number} collectionId - The collectionId of the values to be merged.
     * @param {Array} mergedValues - The values to be merged.
     * @return {Array} The merged and filtered array of values.
     */
    const getUniqueMergedValues = (collectionId, mergedValues) => {

        const getUniqueEntries = (data, key) => {
            if (data.some(d => !d[key])) {
                return data;
            }

            const out = [];
            data.forEach(d => {
                if (!out.find(o => o[key] === d[key])) {
                    out.push(d);
                }
            });
            return out;
        };

        if (Array.isArray(mergedValues)) {
            const hasCorrectCollectionId = mergedValues.filter(nv => nv.collectionId === collectionId).map(v => {
                const value = {
                    ...v,
                    reference: {
                        ...v.reference
                    }
                };
                delete value._action;
                delete value.reference._action;
                return value;
            });

            const isMissingCorrectCollectionId =
                mergedValues.filter(nv => nv.collectionId !== collectionId)
                    .filter(nvvv => !nvvv.refGuid) // Filter out references as they are irrelevant in this context.
                    .filter(nvv => {
                        if (hasCorrectCollectionId.some(t => typeof (t.reference.title) !== 'undefined')) {
                            return !hasCorrectCollectionId.map(t => t.reference.title).includes(nvv.reference.title);
                        } else {
                            // The language objects does not have the .reference.title property.
                            // NOTE: To avoid duplicates, we hav to do an extra check here.
                            return !hasCorrectCollectionId.map(t => t.title).includes(nvv.title);
                        }
                    }).map(vv => {
                    const refValue = {
                        ...vv,
                        reference: {
                            ...vv.reference
                        }
                    };
                    delete refValue._action;
                    delete refValue.collectionId;
                    delete refValue.reference.id;
                    delete refValue.reference.uniqueId;
                    delete refValue.reference._action;
                    return refValue;
                });

            return [...hasCorrectCollectionId, ...getUniqueEntries(isMissingCorrectCollectionId, 'title')];
        } else {
            return mergedValues;
        }
    };


    /**
     * Takes the new values and merges them with the existing models.
     * @param {Object} newValues - The new values to be merged into the models.
     * @returns {Array} The updated models.
     */
    const getUpdatedModels = (newValues, loadedModels) => {
        let updatedModels = [...loadedModels];
        const allCollectionIds = [...new Set([...updatedModels].map(u => u.collectionId))];
        const values = {...newValues};
        for (let n = 0, max = updatedModels.length; n < max; n++) {
            const model = updatedModels[n];
            const {collectionId} = model;
            for (let i = 0, max = selectedFields.length; i < max; i++) {
                const {name, fn} = selectedFields[i];
                const newValue = values[name];
                let modelField = (name === 'title' || name === 'description') ? model[name] : model.content[name];
                modelField = overwriteOrMergeValue(fn, allCollectionIds, modelField, collectionId, newValue);

                // Update the model's field with the new value:
                if (name === 'title' || name === 'description' || name === 'referenceTypes') {
                    model[name] = modelField;
                } else {
                    model.content[name] = modelField;
                }
            } // end: selectedFields loop
            appendCopyrightAndLicensingInformation(values, updatedModels, n);
            appendReferenceTypes(values, updatedModels, n);
        } // end: models-loop
        return updatedModels;
    };

    /**
     * Resets the `fn` property of each selected field to 'merge'.
     * This is called when the user clicks on the "Reset" button in the
     * "Fields" step of the batch edit form.
     */
    const setSelectedFieldsDefaultFunction = () => {
        Object.keys(selectedFields).forEach(key => {
            selectedFields[key].fn = 'merge';
        });
    };

    /**
     * For each place in model.content.places, check if the reference.title
     * matches the reference.content.name. If not, update the reference.title
     * to match the reference.content.name.
     * Remove possible duplicates.
     * @param {object} models - The models to update
     * @private
     */
    const patchPlaceNames = models => {
        if (!selectedFields.find(f => f.name === 'places')) {
            return;
        }

        for (let j = 0, max = models.length; j < max; j++) {
            const {content} = models[j];
            const {places} = content;
            if (places.length === 0) {
                return;
            }
            for (let i = 0, max = places.length; i < max; i++) {
                const place = places[i];
                const {reference, title} = place;
                const {content} = reference;
                if (content && title !== content.name) {
                    models[j].content.places[i].reference.title = content.name;
                }
            }
            // Remove possible duplicates, that has not been issued a collection ID.
            models[j].content.places = models[j].content.places.filter(p => p.collectionId);
        }
    };


    const loadDocument = async (documentId) => {
        const query = `unique_id: ${documentId}`;
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: query,
                fq: "status: (draft)",
                fl: "title,id",
                sort: "title asc",
                documentType: '(StillImage OR Audio OR Video OR Misc OR Dokument OR Geodata OR Tabell OR Modell)',
                expand: true
            })
        );

        try {
            const res = await damsSearch(searchParams);
            if (res.count > 0) {
                return res.models[0];
            } else {
                return [];
            }
        } catch (e) {
            clientLog('error', 'failed to load the specific document', 'fileupload');
            return [];
        }
    };


    const onSubmit = async (newValues, actions) => {
        actions.setSubmitting(true);

        if (models && models.length > 0) {
            // If the list of models where added during initialization of this component, proceed as usual:
            await _onSubmitLoadedModels(newValues, actions);
            actions.setSubmitting(false);
        } else {
            // If no models where added during initialization of this component, load, and update them individually:
            const documentIds = fileUploadOperationEditFiles.documentIds;
            for (let i = 0, max = documentIds.length; i < max; i++) {
                const documentId = documentIds[i];
                const model = await loadDocument(documentId);
                if (model && model.id) {
                    await _onSubmitLoadedModel(newValues, actions, model);
                }
            }
            if (postDocumentsResponse.failedDocuments && postDocumentsResponse.failedDocuments.length > 0) {
                showErrorSnackbar(
                    t(
                        "snackbarBatchEditErrorMsg",
                        "Lagring feilet for en eller flere dokumenter. Disse vil fortsatt være avkrysset/merket."
                    )
                );
            }
            setSelectedFieldsDefaultFunction();
            actions.setSubmitting(false);
            onComplete(saved, failed);
        }
    };

    /**
     * This function is called when the user submits the form and
     * a document was added to the batch edit operation.
     * It takes the new values and merges them with the existing model,
     * and then sends the updated model to the server using `postDocumentsInChunks`.
     * If there are any failed documents, it shows an error snackbar.
     *
     * NOTE: Only called when the list of models where added during initialization of this component.
     *
     * @param {Object} newValues - The new values to be merged into the model.
     * @param {Object} actions - Formik actions.
     * @param {Object} model - The model to be updated.
     */
    const _onSubmitLoadedModel = async (newValues, actions, model) => {
        const collectionId = model.collectionId;
        const museumsWithIds = getMuseumsFromCollectionIds({
            museums: museums,
            museumCollections: museumCollections,
            collectionIds: [collectionId]
        });
        let modelsWithMergedData = getUpdatedModels(newValues, [model]);
        const createMissingDocRefs = new CreateMissingDocRefs(museumsWithIds, modelsWithMergedData, selectedFields.map(s => s.name));
        modelsWithMergedData = await createMissingDocRefs.create();
        patchPlaceNames(modelsWithMergedData);
        await postDocumentsInChunks(modelsWithMergedData, collectionId, actions);
    }

    /**
     * The callback function for the form submission.
     * It takes the new values and merges them with the existing models,
     * and then sends the updated models to the server using `postDocumentsInChunks`.
     * If there are any failed documents, it shows an error snackbar.
     * @param {Object} newValues - The new values to be merged into the models.
     * @param {Object} actions - Formik actions.
     */
    const _onSubmitLoadedModels = async (newValues, actions, model = null) => {
        let collectionIds = [];
        if (model) {
            collectionIds = [model.collectionId];
        } else if (models) {
            collectionIds = [...new Set([...models].map(u => u.collectionId))];
        }

        const museumsWithIds = getMuseumsFromCollectionIds({
            museums: museums,
            museumCollections: museumCollections,
            collectionIds: collectionIds
        });

        // Get updated model data, and create the necessary references in the database.
        let modelsWithMergedData = getUpdatedModels(newValues, models);
        const createMissingDocRefs = new CreateMissingDocRefs(museumsWithIds, modelsWithMergedData, selectedFields.map(s => s.name));
        modelsWithMergedData = await createMissingDocRefs.create();

        // Patch place names, making their title only contain the name, without any further information.
        patchPlaceNames(modelsWithMergedData);

        await postDocumentsInChunks(modelsWithMergedData);
        if (postDocumentsResponse.failedDocuments["length"] > 0) {
            showErrorSnackbar(
                t(
                    "snackbarBatchEditErrorMsg",
                    "Lagring feilet for en eller flere dokumenter. Disse vil fortsatt være avkrysset/merket."
                )
            );
        }
        setSelectedFieldsDefaultFunction();
    };

    /**
     * Hook used to append licensing and copyright fields.
     */
    useDeepCompareEffect(() => {
        if (failed.length === 0 && saved.length === 0) {
            return;
        }
        if (models && (failed.length + saved.length === models?.length)) {
            // Await complete-state until all documents are finished
            onComplete(saved, failed);
        }
    }, [saved, failed]);

    const filteredInitialValues = selectedFields.reduce(
        (acc, field) => ({
            ...acc,
            [field.name]: initialValues[field.name],
        }),
        {}
    );

    // If "copyrightAndLicensing" is selected, add all files related to copyright/licencing,
    // as these are not the result of selecting an individual field, but a group of fields.
    // NOTE: Each field is added as an empty array, as fields are "cleared" when batch editing objects.
    if (Boolean(selectedFields.find(f => f.name === 'copyrightAndLicensing'))) {
        filteredInitialValues.licenses = [];
        filteredInitialValues.copyrightType = [];
        filteredInitialValues.copyrightTypeDateUntil = [];
        filteredInitialValues.copyrightTypeResponsible = [];
        filteredInitialValues.copyrightTypeOriginator = [];
    }

    /**
     * Hook used to make sure userData are fetched before attempting to fetch museum information,
     * and displaying the form.
     */
    useEffect(() => {
        if (!userData) {
            return;
        }
        setMuseums(userData.appAccess.museums);
        setLoading(false);
    }, [userData]);

    return <DamsForm initialValues={filteredInitialValues} onSubmit={onSubmit}>
        <Box sx={{
            minWidth: {
                xs: '80vw',
                sm: '80vw',
                md: '80vw',
                lg: '50vw',
                xl: '50vw',
            },
        }}>
            {!loading && children}
        </Box>
    </DamsForm>;
};
